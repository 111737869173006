<template>
  <section class="list">
    <h2>Seznam</h2>
    <h3 class="playedTitle" @click="toggleShow">Ukončené zápasy: <i :class="{ fas: true, 'fa-caret-up': showPlayed, 'fa-caret-down': !showPlayed }"></i></h3>
    <div v-if="showPlayed" class="innerList played">
      <div v-for="match in playedMatches" class="match">
        <span class="field">Hřiště: {{ fields[match.fieldId] && fields[match.fieldId].name }}</span>
        <span class="judge">{{ judges[match.judgeId] }}</span>
        <span class="teamA"><Team :bracketTeam="match.bracketTeamA" :includeOutpost="true" /></span>
        <span class="teamB"><Team :bracketTeam="match.bracketTeamB" :includeOutpost="true" /></span>
        <span class="score">
          {{ match.scoreA }}:{{ match.scoreB }}
        </span>
      </div>
    </div>
    <h3>Probíhající zápasy:</h3>
    <div class="innerList">
      <div class="match playing" v-for="match in playingMatches">
        <span class="field">Hřiště: {{ fields[match.fieldId] && fields[match.fieldId].name }}</span>
        <span class="judge">{{ judges[match.judgeId] }}</span>
        <span class="teamA"><Team :bracketTeam="match.bracketTeamA" :includeOutpost="true" /></span>
        <span class="teamB"><Team :bracketTeam="match.bracketTeamB" :includeOutpost="true" /></span>
        <span class="score">{{ match.scoreA }}:{{ match.scoreB }}</span>
      </div>
    </div>
    <h3>Nadcházející zápasy:</h3>
    <div class="innerList upcomming">
      <div class="match" v-for="match in notPlayedMatches">
        <span class="field">Hřiště: {{ fields[match.fieldId] && fields[match.fieldId].name }}</span>
        <span class="judge">{{ judges[match.judgeId] }}</span>
        <span class="teamA"><Team :bracketTeam="match.bracketTeamA" :includeOutpost="true" /></span>
        <span class="teamB"><Team :bracketTeam="match.bracketTeamB" :includeOutpost="true" /></span>
        <span class="score">{{ match.scoreA }}:{{ match.scoreB }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Team from './team.vue'

export default {
  name: 'list',
  components: {
    Team,
  },
  data: () => ({
    showPlayed: false,
  }),
  computed: {
    ...mapState(['fields', 'judges']),
    ...mapGetters(['playedMatches', 'playingMatches', 'notPlayedMatches']),
  },
  methods: {
    toggleShow() {
      this.showPlayed = !this.showPlayed
    }
  }
}
</script>

<style lang="scss">
@import '../constants.scss';

.list {
  display: grid;
  justify-content: center;

  .playedTitle {
    cursor: pointer;
  }

  .innerList {
    display: grid;
    justify-content: center;
    justify-items: stretch;
    row-gap: $padding;

    .match {
      display: grid;
      grid-template-areas: ". field ." "teamA score teamB" ". judge .";
      grid-template-columns: 1fr max-content 1fr;
      grid-gap: $padding;
      width: 20rem;

      padding: $padding $padding-big;
      color: $box-text-color;
      background-color: $box-back-color;
      border-radius: $padding;

      &.playing {
        background-color: $playing-back-color;
      }

      .teamA {
        grid-area: teamA;
        justify-self: center;
        font-size: 1.2rem;
      }
      .teamB {
        grid-area: teamB;
        justify-self: center;
        font-size: 1.2rem;
      }
      .score {
        grid-area: score;
        justify-self: center;
        font-size: 1.2rem;
      }
      .field {
        grid-area: field;
        justify-self: center;
      }
      .judge {
        grid-area: judge;
        justify-self: center;
        color: $box-text-color-disabled;
      }
    }
  }

  table {
    tr {

      border-radius: $padding;

      color: $box-text-color;
      background-color: $box-back-color;

      td {
        padding: $padding $padding;
        margin: $padding;
      }
    }
  }
}
</style>
