export const Delay = timeMs => new Promise(res => setTimeout(res, timeMs))

export const _ = {
  forEach(data, func) {
    Object.keys(data ?? {}).forEach((key, index) => {
      let item = data[key];
      func(item, key, index);
    });
  },
  select(data, func) {
    let result = {};
    Object.keys(data ?? {}).forEach((key, index) => {
      let item = data[key];
      result[key] = func(item, key, index)
    });
    return result;
  },
  selectMany(data, func) {
    let result = {};
    Object.keys(data ?? {}).forEach((key, index) => {
      var item = data[key];
      var innerMap = func(item, key, index);
      Object.keys(innerMap ?? {}).forEach(key => {
        result[key] = innerMap[key];
      });
    })
    
    return result;
  },
  filter(data, condition) {
    let result = {};
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      if (condition(item, key))
        result[key] = item;
    });

    return result;
  },
  order(data, orderBy) {
    var keys = Object.keys(data ?? {});
    keys.sort((keyA, keyB) => {
      var valueA = orderBy(data[keyA], keyA);
      var valueB = orderBy(data[keyB], keyB);

      return common.compare(valueA, valueB);
    });

    return keys.map(key => data[key]);
  },
  first(data, condition) {
    for (const key in (data ?? {})) {
      let item = data[key];

      if (condition(item, key))
        return item;
    }

    return null;
  },
  groupBy(data, selector) {
    let result = {};
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      let selectorValue = selector(item, key);
      if (result[selectorValue] === undefined)
        result[selectorValue] = {};

      result[selectorValue][key] = item;
    });

    return result;
  },
  any(data, selector, def = null) {
    try {
      var keys = Object.keys(data ?? {});
      if (keys.length == 0 && def != null) {
        return def;
      }
      keys.forEach(key => {
        if (selector(data[key], key))
          throw new Error();
      });
    }
    catch {
      return true;
    }
    return false;
  },
  all(data, selector, def = null) {
    try {
      var keys = Object.keys(data ?? {});
      if (keys.length == 0 && def != null) {
        return def;
      }
      keys.forEach(key => {
        if (!selector(data[key], key))
          throw new Error();
      });
    }
    catch {
      return false;
    }
    return true;
  },
  sum(data, selector) {
    let result = 0;
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      let selectorValue = selector(item, key);
      result += selectorValue;
    });

    return result;
  },
  count(data, selector = null) {
    if (selector == null) {
      return Object.keys(data ?? {}).length;
    }

    let result = 0;
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      if (selector(item, key))
        result += 1;
    });

    return result;
  },
  countGroup(data, selector) {
    let result = {};
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      let selectorValue = selector(item, key);
      if (result[selectorValue] === undefined)
        result[selectorValue] = 0;

      result[selectorValue] += 1;
    });

    return result;
  },
  max(data, selector = i => i) {
    let result = null;
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      let selectorValue = selector(item, key);
      if (selectorValue > result) {
        result = selectorValue;
      }
    });

    return result;
  },
  min(data, selector = i => i) {
    let result = null;
    Object.keys(data ?? {}).forEach(key => {
      let item = data[key];

      let selectorValue = selector(item, key);
      if (selectorValue < result) {
        result = selectorValue;
      }
    });

    return result;
  },
}

export const common = {
  compare(valA, valB) {
    if (valA == undefined && valB == undefined)
      return 0;

    if (valA == undefined)
      return 1;

    if (valB == undefined)
      return -1;

    if (valA < valB)
      return -1;

    if (valA > valB)
      return 1;

    return 0;
  },
};
