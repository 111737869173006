import { createStore } from 'vuex'
import axios from 'axios'
import { Guid } from 'js-guid'
import { Delay, _ } from './tools'
import { getName } from './localization'

export default createStore({
  state: {
    windowSize: {
      width: 0,
      height: 0,
    },
    filters: {
      searchQuery: "",
      teams: true,
      fields: true,
      judges: true,
      categories: true,
      outposts: true,
      brackets: true,
    },
    showMap: false,
    notifications: {},
    requests: {},
    brackets: {},
    bracketTeams: {},
    teams: {},
    matches: {},
    fields: {},
    judges: {},
    sysVersion: '',
  },
  getters: {
    searchQueryLower: store => store.filters.searchQuery.toLowerCase(),
    matchesWithLinks: (store) => _.select(store.matches, m => ({
      ...m,
      bracket: store.brackets[m.bracketId],
      bracketTeamA: store.bracketTeams[m.bracketTeamAId],
      bracketTeamB: store.bracketTeams[m.bracketTeamBId],
      field: store.fields[m.fieldId],
      judge: store.judges[m.judgeId],
      winner: store.teams[m.winnerId],
      teamA: store.teams[store.bracketTeams[m.bracketTeamAId]?.teamId],
      teamB: store.teams[store.bracketTeams[m.bracketTeamBId]?.teamId],
    })),
    matchesBaseFiltered: (store, getters) => _.filter(getters.matchesWithLinks, m => m.bracketTeamA != null && m.bracketTeamB != null),
    matchesBaseFilteredOrdered: (store, getters) => _.order(getters.matchesBaseFiltered, m => m.order),
    matchesFiltered: (store, getters) => _.filter(getters.matchesBaseFilteredOrdered, m =>
      (store.filters.teams && (getName(m.teamA?.id)?.toLocaleLowerCase().includes(getters.searchQueryLower) || getName(m.teamB?.id)?.toLocaleLowerCase().includes(getters.searchQueryLower)))
      || (store.filters.fields && m.field?.name.toLowerCase().includes(getters.searchQueryLower))
      || (store.filters.judges && m.judge?.toLowerCase().includes(getters.searchQueryLower))
      || (store.filters.categories && m.bracket.categoryId.toLowerCase().includes(getters.searchQueryLower))
      || (store.filters.outposts && (m.teamA?.outpostId.toString().includes(getters.searchQueryLower) || m.teamB?.outpostId.toString().includes(getters.searchQueryLower)))
      || (store.filters.brackets && m.bracket.name.toLowerCase().includes(getters.searchQueryLower))),
    playedMatches: (store, getters) => _.filter(getters.matchesFiltered, m => m.winnerId != null),
    playingMatches: (store, getters) => _.filter(getters.matchesFiltered, m => m.winnerId == null && m.isStarted),
    notPlayedMatches: (store, getters) => _.filter(getters.matchesFiltered, m => m.winnerId == null && !m.isStarted),
    bracketsFiltered: (store, getters) => _.select(_.groupBy(getters.matchesFiltered, m => m.bracketId), (g, id) => store.brackets[id]),
  },
  mutations: {
    setFilterQuery: (state, { query, filters }) => {
      Object.keys(filters).forEach(key => state.filters[key] = filters[key]);
      state.filters.searchQuery = query;
    },
    changeFilter: (state, { key, value }) => {
      state.filters[key] = value;
    },
    toggleMap: (state) => {
      state.showMap = !state.showMap;
    },
    resize: (state) => {
      state.windowSize.width = `${window.innerWidth}px`;
      state.windowSize.height = `${window.innerHeight}px`;

      document.body.style.width = state.windowSize.width;
      document.body.style.height = state.windowSize.height;
    },
    loaded: (state, payload) => {
      console.log("loaded", payload)
      const { brackets, bracketTeams, teams, matches, fields, judges, bracketGraphSvgs, sysVersion } = payload
      state.brackets = brackets
      state.bracketTeams = bracketTeams
      state.teams = teams
      state.matches = matches
      state.fields = fields
      state.judges = judges
      state.bracketGraphSvgs = bracketGraphSvgs
      state.sysVersion = sysVersion
    },
  },
  actions: {
    load: context => {
      context.dispatch('get', {
        uri: `/api/public/load?sysVersion=${context.state.sysVersion}`,
        callback: res => context.commit('loaded', res),
      })
    },

    get: async (context, { uri, callback }) => { // uri, callback
      var requestId = Guid.newGuid()
      context.state.requests[requestId] = true

      var result = await axios.get(uri)
        .catch(err => {
          // ignore 304
          if (err?.response?.status == 304)
            return err.response

          console.warn("err", err)
          context.dispatch('notify', { type: 'error', message: err })
          return err.response
        })
      
      if (result.status == 200 && callback != null)
        callback(result.data)

      delete context.state.requests[requestId]
    },

    notify: async (context, { type, message }) => { // type, message
      var notificationId = Guid.newGuid()
      context.state.notifications[notificationId] = { type, message }
      await Delay(10000)
      delete context.state.notifications[notificationId]
    },
  },
  modules: {
  }
})
