<template>
  <table class="roundRobin">
    <tr class="head">
      <th class="teamX"></th>
      <th v-for="teamY in bracketTeams" :key="teamY.id">
        <Team :bracketTeam="teamY" :includeOutpost="true" />
      </th>
      <th class="standing">Pořadí</th>
    </tr>
    <tr v-for="teamX in bracketTeams" :key="teamX.id">
      <th class="teamX">
        <Team :bracketTeam="teamX" :includeOutpost="true" />
      </th>
      <td v-for="teamY in bracketTeams" :key="teamY.id">
        <Match :bracket="bracket" :bracketTeamX="teamX" :bracketTeamY="teamY" />
      </td>
      <th class="standing">{{ standing(teamX) }}</th>
    </tr>
  </table>
</template>

<script>
import { _ } from '@/store/tools'
import Team from './team.vue'
import Match from './bracket-roundRobin-match.vue'

export default {
  name: 'roundRobin',
  components: {
    Team,
    Match,
  },
  props: {
    bracket: {},
  },
  computed: {
    bracketTeams() {
      const filteredBracketTeams = _.filter(this.$store.state.bracketTeams, bt => bt.bracketId == this.bracket.id && bt.bracketStanding == null)
      const bracketTeamsWithLinks = _.select(filteredBracketTeams, bt => {
        var pairTeam = _.first(this.$store.state.bracketTeams, bts => bts.bracketId == this.bracket.id && bts.teamId == bt.teamId && bts.bracketStanding != null);
        return {
          ...bt,
          team: this.$store.state.teams[bt.teamId],
          bracketStanding: pairTeam.bracketStanding,
          decidedByAdmin: bt.decidedByAdmin || pairTeam.decidedByAdmin,
        }
      });
      const bracketTeamsOrdered = _.order(bracketTeamsWithLinks, t => t.teamId);
      return bracketTeamsOrdered;
    },

    standing() {
      return (teamX) => {
        // not played bracket -> empty
        const bracketMatches = _.filter(this.$store.state.matches, m => m.bracketId == this.bracket.id);
        const bracketIsPlayed = _.any(bracketMatches, m => m.scoreA > 0 || m.scoreB > 0);
        if (!bracketIsPlayed)
          return "";

        // decided by admin
        if (teamX.decidedByAdmin)
          return `${teamX.bracketStanding + 1}.`;

        // correct standing
        const sameStrengthTeams = _.filter(this.bracketTeams, bt => bt.strength == teamX.strength && !bt.decidedByAdmin);
        if (_.count(sameStrengthTeams) <= 1)
          return `${teamX.bracketStanding + 1}.`;

        // multiple teams has same strength
        var standing = _.min(sameStrengthTeams, bt => bt.bracketStanding);
        return `${standing + 1}.`;
      }
    },
  },
}
</script>

<style lang="scss">
@import '../constants.scss';

.roundRobin {
  padding: $padding;
  border-radius: $padding;
  color: $box-text-color;
  background-color: $box-back-color;

  .head th {
    border-bottom: 1px solid $box-text-color;
  }

  .teamX {
    border-right: 1px solid $box-text-color;
    text-align: right;
  }

  .standing {
    border-left: 1px solid $box-text-color;
    text-align: right;
  }

  td,
  th {
    padding: $padding;
    text-align: center;
    text-wrap: nowrap;
  }

  th:not(:nth-last-child(2)):not(:last-child):not(:first-child),
  td:not(:nth-last-child(2)):not(:last-child):not(:first-child) {
    border-right: 1px solid $graph-color;
  }

  tr:not(:first-child):not(:last-child) th,
  tr:not(:first-child):not(:last-child) td {
    border-bottom: 1px solid $graph-color;
  }
}
</style>
